import { DELETE, GET, POST, PUT, Send, request } from "./request"

export const getParamsWithFilters = (params, filters) => {
	const carKeys = ["brand_id", "model_id", "vin"]
	const newParams = params ? { ...params } : {}

	if (filters) {
		Object.entries(filters).forEach(([key, value]) => {
			if (value) {
				newParams[
					`filters${carKeys.includes(key) ? "[car]" : ""}[${key}]`
				] = value
			}
		})
	}

	return newParams
}

export const getCarsList = (params) => {
	const newParams = {
		"expand[0]": "owner",
		"expand[1]": "color",
		"expand[2]": "car.body",
		"expand[3]": "car.brand",
		"expand[4]": "car.engine",
		"expand[5]": "status",
		"expand[6]": "preview.file",
		"expand[7]": "car.model",
		"expand[8]": "city",
		"expand[9]": "car.transmission",
		"expand[10]": "buyer",
		"expand[11]": "statusReports",
		"expand[12]": "statusReports.file",
		"orders[created_at]": "desc",
		...(params || {})
	}
	return GET("cars/sales", newParams)
}

export const getSingleCar = (saleId) => {
	const params = {
		"expand[0]": "statusReports.responsible",
		"expand[1]": "statusReports.status",
		"expand[2]": "statusReports.updatedBy",
		"expand[3]": "statusReports.closedBy",
		"expand[4]": "statusReportFile",
		"expand[5]": "reservedFor",
		"expand[6]": "car.drive",
		"expand[7]": "statusReports.file"
	}

	return GET(`cars/sales/${saleId}`, params)
}

export const getStatusReportsElementsRequest = (report_id, group_id) => {
	return GET(`status_report/${report_id}/defects?group_id=${group_id}`)
}

export const getImportDefectsRequest = (sale_id, group_id) => {
	return GET(`cars/sales/${sale_id}/import/defects?group_id=${group_id}`)
}

export const getStatusReportTransitions = () => {
	return GET("status_report/transitions")
}

export const postChangeStatusReportTransition = (
	report_id,
	to_status,
	params
) => {
	return POST(`status_report/${report_id}/transition`, {
		to_status,
		...params
	})
}

export const getReportsCommentsRequest = (status_report_id) => {
	return Send(`status_report/${status_report_id}/comments`)
}

export const createReportCommentRequest = (status_report_id, body) => {
	return Send(`status_report/${status_report_id}/comments`, "POST", { body })
}

export const filteredSalesRequest = ({ filters, index }) => {
	const url = `cars/sales?page=${index}&expand[0]=owner&expand[1]=color&expand[2]=car.body&expand[3]=car.brand&expand[4]=car.engine&expand[5]=preview.file`

	const allFilters = {}

	for (const [key, value] of Object.entries(filters)) {
		if (value) {
			Object.assign(allFilters, { [`filters[${key}]`]: value })
		}
	}

	return Send(url, "GET", {}, false, allFilters)
}

export const postAddCarSaleDocuments = (saleId, data) => {
	return POST(`cars/sales/${saleId}/documents`, data, undefined, {
		headers: { "content-type": "multipart/form-data" }
	})
}

export const getCarSaleDocumentsRequest = (sale_id) => {
	return Send(`cars/sales/${sale_id}/documents`)
}

export const getCarSaleImportFieldsRequest = (sale_id) => {
	return GET(`cars/sales/${sale_id}/import/fields`)
}

export const removeCarSaleDocument = (saleId, document_ids) => {
	return DELETE(`cars/sales/${saleId}/documents`, { document_ids })
}

export const postAddCarSalePhoto = (saleId, data) => {
	return POST(`cars/sales/${saleId}/photos`, data, undefined, {
		headers: { "content-type": "multipart/form-data" }
	})
}

export const getCarSalePhotos = (saleId) => {
	return GET(`cars/sales/${saleId}/photos`)
}

export const removeCarSalePhoto = (saleId, photo_ids) => {
	return DELETE(`cars/sales/${saleId}/photos`, { photo_ids })
}

export const getTires = (reportId) => {
	return GET(`status_report/${reportId}/tire_sets`)
}

export const putStatusReportId = (reportId, responsible_id) => {
	return PUT(`status_report/${reportId}`, { responsible_id })
}

export const getCarSaleTransitions = () => {
	return GET("cars/sales/transitions")
}

export const postChangeStatusCarTransition = (saleId, toStatus) => {
	return POST(`cars/sales/${saleId}/transition`, { to_status: toStatus })
}

export const getLastReport = (saleId, statusReportId) => {
	return GET(`cars/sales/${saleId}/defects?status_report_id=${statusReportId}`, { group_id: 41 })
}

export const getCurrentTireSets = (saleId, statusReportId) => {
	return GET(`cars/sales/${saleId}/tire-sets?status_report_id=${statusReportId}`)
}

export const getCurrentCarChanges = (carId, params) => {
	return GET(`cars/sales/${carId}/changes`, params)
}

export const getCarTradesHistory = (carId, params) => {
	return GET(`cars/sales/${carId}/history`, params)
}

export const getCarSaleFillableFields = (car_type_key) => {
	return GET("cars/sales/fillable_fields", { car_type_key })
}

export const getCarSaleComments = (saleId) => {
	return GET(`cars/sales/${saleId}/comments`)
}

export const postAddSaleComment = (saleId, data) => {
	return POST(`cars/sales/${saleId}/comments`, data)
}

export const putUpdateSaleComment = (saleId, commentId, data) => {
	return PUT(`cars/sales/${saleId}/comments/${commentId}`, data)
}

export const getTasksList = (params) => {
	const newParams = {
		"expand[0]": "createdBy",
		"expand[1]": "errorsFile",
		"expand[2]": "importFile",
		"expand[3]": "status",
		...(params || {})
	}
	return GET("import/tasks", newParams)
}

export const getServices = () => {
	return GET("import/services")
}

export const postImportTasks = (config) => {
	return request("import/tasks", "POST", {
		...config,
		headers: { "content-type": "multipart/form-data" }
	})
}

export const postCreateCarSale = (data) => {
	return POST("cars/sales", data)
}

export const putUpdateCarSale = (id, data) => {
	return PUT(`cars/sales/${id}`, data)
}

export const getCarFields = (id) => {
	return GET(`cars/sales/${id}/fields`)
}

export const getCarFieldsGroups = (car_type_key) => {
	return GET("cars/sales/field_groups?asTree=1", { car_type_key })
}

export const putUpdateCarFields = (id, data) => {
	return PUT(`cars/sales/${id}/fields`, data)
}
