import { Link } from "react-router-dom"

const STATUS = {
	sale_new_car: "green bold", // Новое Т/C | Проверить отчет
	sale_new: "", // В подготовке | Проверить отчет
	sale_sold: "", // Продан
	sale_waiting_approval: "", // Ожидает подтверждения
	sale_on_sale: "", // На торгах
	sale_on_sale_reserved: "" // На торгах (в резерве)
}

let s = false

const COLUMNS = [
	{
		dataField: "name",
		text: "Название",
		formatter: (cell, row) => (
			<Link to={`/car/${row.id}`}>
				{row.car.brand?.name} {row.car.model?.name}{" "}
				{row.car.year ? `${row.car.year} г.в.` : ""}{" "}
				{row.car.engine_volume} {row.car.transmission?.name}
			</Link>
		)
	},
	{
		dataField: "seller",
		text: "Продавец",
		formatter: (cell, row) => (
			<a
				href={`/${row.owner?.full_name ? "user" : "company"}/${row.owner?.id
					}`}
				target="_blank"
				rel="noreferrer"
			>
				{row.owner?.display_name}
			</a>
		)
	},
	{
		dataField: "vin",
		text: "VIN",
		formatter: (cell, row) => row.car.vin
	},
	{
		dataField: "reg_number",
		text: "Гос. номер",
		formatter: (cell, row) => row.status_reports[row.status_reports?.length - 1]?.reg_number ?? "-"
	}
]

export const getColumns = (hasPermission, hasPermissionStatusView) => {
	const columns = [...COLUMNS]

	if (hasPermissionStatusView) {
		columns.push({
			dataField: "status",
			text: "Статус",
			formatter: (cell, row) => (
				<span className={STATUS[row.status?.code]}>{row.status?.name}</span>
			)
		})
	}

	columns.push({
		dataField: "report",
		text: "Статус отчета",
		formatter: (cell, row) => row.display_status
	})

	if (hasPermission) {
		columns.push({
			dataField: "buyer",
			text: "Покупатель ",
			formatter: (cell, row) => (
				<Link to={`/user/${row?.buyer_id}`}>
					{row.buyer?.full_name}
				</Link>
			)
		})
	}

	return columns
}
