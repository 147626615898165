import { deleteDocumentsUser, getDocumentsUser, postDocumentsUser } from '@api/documents'
import classNames from 'classnames'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import Input from '../../Forms/Input/Input'
import Upload from '../Upload/Upload'
import UploadField from '../UploadField/UploadField'
import UploadFileList, { UploadDocumentsProps } from '../UploadFileList/UploadFileList'
import styles from "./UploadDocument.module.scss"

interface UploadDocumentProps {
	className?: string
	edit?: boolean
	id: number
	apiPath?: 'companies' | 'users'
}

const UploadDocument = (props: UploadDocumentProps) => {
	const { className, edit, apiPath = 'users', id } = props
	const [desciption, setDescription] = useState('')
	const [documents, setDocuments] = useState<UploadDocumentsProps[]>([])
	const [loading, setLoading] = useState(false)
	const [reset, setReset] = useState(false)

	const fetchDocuments = async () => {
		await getDocumentsUser(apiPath, id).then(setDocuments)
	}
	useEffect(() => {
		fetchDocuments()
	}, [])

	const onAddDocument = async (files: File[]) => {
		setLoading(true)
		await postDocumentsUser(apiPath, id, [
			{
				description: desciption,
				files: [...files]
			}
		])
		await fetchDocuments()
		setReset(prev => !prev)
		setLoading(false)
	}

	const onRemoveDocument = async (deleteId: number) => {
		await deleteDocumentsUser(apiPath, id, [deleteId])
		setDocuments(documents.filter(x => x.id !== deleteId))
	}

	const onInput = (e: any) => {
		setDescription(e.target.value)
	}

	return (
		<div className={classNames(styles.UploadDocument, className)}>
			<div className="mt-4">
				<div className="title">Документы</div>
				<div className={clsx(styles.section, 'mt-2')}>
					<UploadFileList documents={documents} onRemoveFile={onRemoveDocument} isDelete={edit} />
					{edit &&
						<>
							<UploadField
								label={'Введите название документа'}
							>
								<Input
									placeholder={'Введите название документа'}
									value={desciption}
									onInput={onInput}
									reset={reset}
								/>
							</UploadField>
							<UploadField>
								<Upload onAddDocument={onAddDocument} loading={loading} />
							</UploadField>
						</>
					}
				</div>
			</div>
		</div>
	)
}

export default UploadDocument