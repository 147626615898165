import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { Navigate, useNavigate, useParams } from "react-router-dom"

import { blockUsersRequest, getRequisitesTemplates, getUserById, postUserApprove, unlockUsers } from "@api/users"
import { NO_PERMISSION_URL, phoneNormalize } from "@utils/common"
import { formatPhoneMask } from "@utils/format"
import { Button } from "../../components/button/button"
import CommentEdit from "../../components/CommentEdit/CommentEdit"
import { DropdownButton } from "../../components/dropdown/dropdown-button"
import { Loader } from "../../components/loader/loader"
import { RadioButton } from "../../components/radio-button/radio-button"
import UploadDocument from '../../components/upload-documents/UploadDocument/UploadDocument'
import { meStore } from "../../mobx/me"
import { UserType } from "../../types/UserType"
import RoleViewAdmin from "../role/RoleViewAdmin/RoleViewAdmin"
import "./user.scss"
import { getCompanies } from "./utils"

type UserAny = any
type RenderDinamicFieldProps = {
	is_required: boolean
	key: string
	name: string
	type: string
}

export const User = observer(() => {
	const navigate = useNavigate()
	const [user, setUser] = useState<UserType>()
	const { id } = useParams()
	const [show, setShow] = useState(false)
	const [fields, setFields] = useState([])
	const [isRolesAdd, setIsRolesAdd] = useState(false)

	const requestUser = () => {
		if (id) getUserById(id).then(data => {
			setUser(data)
			if (meStore.hasPermission("roles.assign")) setIsRolesAdd(true)
		})
	}

	useEffect(() => {
		requestUser()

		getRequisitesTemplates().then((resp) => {
			if (resp?.personal?.fields) {
				setFields(Object.values(resp.personal.fields))
			}
		})
	}, [])

	const handleClose = () => setShow(false)

	const blockUser = async () => {
		await blockUsersRequest([id])
		await requestUser()
		handleClose()
	}

	if (meStore.noPermission("admin.users.view")) {
		return <Navigate to={NO_PERMISSION_URL} />
	}

	if (meStore.data === null) {
		return null
	}

	if (!user) {
		return <div className="single-user-page-wrapper"><Loader /></div>
	}

	const renderDynamicField = (item: RenderDinamicFieldProps) => {
		return (
			<div key={item.key} className="user-info__field-wrapper">
				<div className="name">{item.name}</div>
				<div className="value">
					{item.key === "need_deposit" ?
						(user?.requisite?.[item.key] ? "Да" : "Нет")
						: (user?.requisite?.[item.key] || "-")
					}
				</div>
			</div>
		)
	}

	const getAdditionalRoles = () => {
		const roles: any[] = []

		user.approve_requests?.forEach((item: any) => {
			roles.push(...item.additional_data.roles)
		})

		return roles
	}

	const elActions = () => {
		if (meStore.hasPermission("admin.users.edit")) {
			const options = [
				{
					label: "Изменить пароль",
					value: "changePassword",
					onClick: () => navigate(`/users/${id}/change-password`)
				}]

			if (user?.metadata?.actions?.update) {
				options.push({
					label: "Редактировать",
					value: "edit",
					onClick: () => navigate(`/users/${user.id}/edit`)
				})
			}

			if (user?.metadata?.actions?.lock) {
				options.push({
					label: "Заблокировать",
					value: "block",
					onClick: () => {
						setShow(true)
					}
				})
			} else {
				options.push({
					label: "Разблокировать",
					value: "block",
					onClick: async () => {
						await unlockUsers([id])
						requestUser()
					}
				})
			}

			if (user?.email_verified_at && user?.metadata?.actions?.approve) {
				options.push({
					value: "",
					label: "Подтвердить",
					onClick: () => postUserApprove({ "users": [{ "user_id": id, "is_approved": true }] })
				})
			}
			return (
				<div className="d-flex actions">
					<div className="d-flex align-items-center action">
						<DropdownButton
							btnText="Действия"
							options={options}
						/>
					</div>
				</div>
			)
		}
	}

	return (
		<div className="single-user-page-wrapper">
			<div className="single-user-page-wrapper__header">
				<div className="title">
					{user.last_name} {user.first_name}
				</div>
				{elActions()}
			</div>
			<div className="user-info mt-4">
				<div className="user-info__field-wrapper">
					<div className="name">ФИО</div>
					<div className="value">{user.last_name} {user.first_name} {user.middle_name}</div>
				</div>
				<div className="user-info__field-wrapper">
					<div className="name">Телефон</div>
					<div className="value">{formatPhoneMask(user.phone)}</div>
				</div>
				<div className="user-info__field-wrapper">
					<div className="name">Город</div>
					<div className="value">{user.city ? user.city.name : "-"}</div>
				</div>
				<div className="user-info__field-wrapper">
					<div className="name">Почта</div>
					<div className="value">{user.email}</div>
				</div>
				<div className="user-info__field-wrapper">
					<div className="name">Ответственный менеджер</div>
					<div className="value">
						{user.responsible_id ? (
							<>
								{user.responsible?.full_name}
								{user?.responsible?.email && <div><a
									href={`mailto:${user?.responsible?.email}`}
								>
									{user?.responsible?.email}
								</a></div>}
								{user?.responsible?.phone && <div>
									<a
										href={`tel:+${phoneNormalize(
											user.responsible?.phone
										)}`}
									>
										+
										{phoneNormalize(
											user.responsible?.phone
										)}
									</a>
								</div>}
							</>
						) : "-"}
					</div>
				</div>
				<div className="user-info__field-wrapper">
					<div className="name">Заявленная компания</div>
					<div className="value">{user.declared_company_name || "-"}</div>
				</div>
				<div className="user-info__field-wrapper">
					<div className="name">Правовая форма</div>
					<div className="value">{user.legal_form?.name || "-"}</div>
				</div>
				<div className="user-info__field-wrapper">
					<div className="name">Компании</div>
					<div className="value user__companies">
						{getCompanies(user)}
					</div>
				</div>
				<div className="user-info__field-wrapper">
					<div className="name">Email подтвержден</div>
					<div className="value">{user?.email_verified_at ? "Да" : "Нет"}</div>
				</div>
				<div className="mt-2 user-info__field-wrapper">
					<div className="name">Желаемая роль (при регистрации)</div>
					<div className="value d-flex gap-5px">
						{getAdditionalRoles().map((role, index) => {
							return <span key={`${role}-${index}`}>{role}</span>
						})}
					</div>
				</div>
				<div className="user-info__field-wrapper align-items-center">
					<div className="name w-30">Депозит</div>
					<div className="d-flex gap-10px align-items-center h-40px">
						<RadioButton
							id="input-yes"
							label="Да"
							name="requisite.need_deposit"
							value="true"
							disabled={true}
							defaultChecked={user.requisite?.need_deposit === true}
						/>
						<RadioButton
							id="input-no"
							label="Нет"
							value="false"
							name="requisite.need_deposit"
							disabled={true}
							defaultChecked={user.requisite?.need_deposit === false}
						/>
					</div>
				</div>
			</div>

			{meStore.hasPermission("admin.users.edit") &&
				<div className="mt-4">
					<div className="title">Комментарий</div>
					<div className="mt-3">
						<CommentEdit id={id} comment={user?.admin_comment} type={"user"} />
					</div>
				</div>
			}

			{fields.length > 0 && (
				<div className="mt-4">
					<div className="title">Реквизиты</div>
					<div className="user-info mt-3">
						{fields.map((item: UserAny) => (item.key === "agent_contract" ? (
							<div key={item.key} className="user-info__field-wrapper">
								<div className="name">Договор</div>
								<div className="value">
									{user?.requisite?.agent_contract?.url ? (
										<a href={user.requisite.agent_contract.url}
											download={user.requisite.agent_contract.original_name}>
											{user.requisite.agent_contract.original_name}
										</a>
									) : "-"}
								</div>
							</div>
						) : renderDynamicField(item)))}
					</div>
				</div>
			)}

			{id && <UploadDocument id={parseInt(id)} />}

			{isRolesAdd && <RoleViewAdmin url={`/user/${user.id}`} items={user.roles} isUser={true} />}

			{show && (
				<div className="modal-wrapper">
					<Modal
						show={true}
						onHide={handleClose}
						className="d-flex align-items-center justify-content-center"
					>
						<div className="content-modal">
							<div className="head-text">
								Заблокировать пользователя: <br /> {user.last_name} {user.first_name}
							</div>
							<div className="actions d-flex mt-3 gap-20px">
								<Button onClick={blockUser} preset="secondary">
									Заблокировать
								</Button>
								<Button onClick={handleClose} preset="primary">
									Отмена
								</Button>
							</div>
						</div>
					</Modal>
				</div>
			)}
		</div>
	)
})
