import clsx from "clsx"
import { useEffect, useState } from "react"

import { getRequisitesSearch } from "@api/auctions"
import { IconSearch } from "@components/icons/seach"
import { ChooseSellerModal } from "@components/input-requisites-search/choose-seller-modal"
import { Input } from "@components/input/input"
import { Suggestions } from "@components/suggestions/suggestions"

import "./input-requisites-search.scss"

const TEMPLATES = {
	legal: {
		key: 'company',
		type: 'company'
	},
	personal: {
		key: 'user',
		type: 'user'
	}
}

export const InputRequisitesSearch = (props) => {
	const [inputValue, setInputValue] = useState(props.defaultValue)
	const [inputHiddenValue, setInputHiddenValue] = useState(
		props.defaultHiddenValue || ""
	)

	const [options, setOptions] = useState([])
	const [isShowChooseSellerModal, setIsShowChooseSellerModal] =
		useState(false)

	const onSelectSeller = (row, type) => {
		if (row) {
			setInputValue(() =>
				type === "user" ? row?.full_name : row?.display_name
			)
			props.onChange(row.id)
			setIsShowChooseSellerModal(false)
		}
	}

	useEffect(() => {
		setInputValue(props.defaultValue?.label)
		setInputHiddenValue(props.defaultValue?.value)
	}, [props.defaultValue])

	useEffect(() => {
		if (props.resetValue) setInputValue("")
	}, [props.resetValue])

	// useEffect(() => {
	// 	setInputValue('')
	// 	setInputHiddenValue('')
	// }, [props.reset])

	const onInputChange = (e) => {
		const { value } = e.target
		console.log(value)
		setInputValue(value)
		if (value.length >= 3) {
			getRequisitesSearch({
				type_id: props.typeId ?? "2",
				name: value
			}).then((resp) => {
				const options = []

				resp.data.forEach((item) => {
					let obj = TEMPLATES[item.template]
					options.push({ label: item[obj.key].display_name, value: item[obj.key].id, type: obj.type })
				})

				setOptions(options)
			})
		}
	}

	return (
		<>
			<Suggestions
				value={inputValue}
				items={options}
				onSelectItem={(item) => {
					setInputValue(item.label)
					setInputHiddenValue(item.value)
					if (props.onChange)
						props.onChange({
							label: item.label,
							value: item.value,
							type: item.type
						})
					setOptions([])
				}}
			>
				<div className="position-relative">
					{props.isChooseSeller && (
						<span onClick={() => setIsShowChooseSellerModal(true)}>
							<IconSearch className="input-requisites-search__icon" />
						</span>
					)}
					<Input
						onChange={props.disabled ? undefined : onInputChange}
						value={inputValue}
						className={clsx(props.className, {
							"input-requisites-search__with-icon":
								props.isChooseSeller
						})}
						placeholder={props.placeholder}
						autoComplete="off"
						disabled={props.disabled}
						size={props.size}
						required={props.required}
						onBlur={() => {
							setTimeout(() => {
								setOptions([])
							}, 200)
						}}
					/>
					<input
						type="hidden"
						name={props?.name}
						value={inputHiddenValue}
					/>
				</div>
			</Suggestions>
			{isShowChooseSellerModal && (
				<ChooseSellerModal
					handleClose={() => setIsShowChooseSellerModal(false)}
					onSelectSeller={onSelectSeller}
				/>
			)}
		</>
	)
}
