import { observer } from "mobx-react-lite"
import moment from "moment"
import { useEffect, useState } from "react"
import { Accordion, Modal } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"

import {
	getSingleCar,
	postChangeStatusReportTransition,
	putStatusReportId
} from "@api/cars"
import { getStatusReportsGroups } from "@api/reports"
import { getTechSpecialists } from "@api/users"
import { DropdownButton } from "@components/dropdown/dropdown-button"
import { Select } from "@components/select/select"
import useMediaQuery from "@hooks/use-media-query"
import { meStore } from "@mobx/me"

import { StateAccordion } from "./accordion-components/state-accordion"
import { WheelsAccordion } from "./accordion-components/wheels-accordion"

import ReportButtonSimple from "@pages/report/accordion-components/ReportButtonSimple/ReportButtonSimple"
import DocumentsReport from "@pages/report/components/DocumentsReport/DocumentsReport"
import PhotoReport from "@pages/report/components/PhotoReport/PhotoReport"
import RequiredFieldsVehicle from "@pages/report/components/RequiredFieldsVehicle/RequiredFieldsVehicle"
import clsx from "clsx"
import TextArea from "../../components/form/TextArea/TextArea"
import ReportButton from "./accordion-components/ReportButton/ReportButton"
import { Comment } from './components/comment'
import "./components/comments-accordion.scss"
import "./report.scss"

export const Report = observer(() => {
	const { saleId, reportId } = useParams()
	const isMobile = useMediaQuery()

	const [accordionCurrentKey, setAccordionCurrentKey] = useState("0")
	const [car, setCar] = useState()
	const [report, setReport] = useState()
	const [isClosedReport, setIsClosedReport] = useState(false)
	const [showModalRejectReport, setShowModalRejectReport] = useState(false)
	const [showModalCloseReport, setShowModalCloseReport] = useState(false)
	const [showModalChangeResponsibleId, setShowModalChangeResponsibleId] =
		useState(false)
	const [statusReportsGroups, setStatusReportsGroups] = useState()
	const [comment, setComment] = useState("")
	const [techSpecialists, setTechSpecialists] = useState()
	const [disabledChangeDefects, setDisabledChangeDefects] = useState(false)
	const [keyVechicle, setKeyVechicle] = useState("")
	const [form, setForm] = useState({
		responsible_id: ""
	})

	const requestCar = () => {
		getSingleCar(saleId).then((resp) => {
			setCar(resp)
			setKeyVechicle(resp.car?.type?.key)
			let report = resp.status_reports?.find(
				(item) => item.id === Number(reportId)
			)
			if (!meStore.hasPermission("admin.auctions.edit")) {
				setDisabledChangeDefects(
					report.status?.code === "in_progress" ||
					report.status?.code === "closed"
				)
			}
			setReport(report)
			setIsClosedReport(report?.status?.code === "closed")
		})
	}

	useEffect(() => {
		requestCar()
		getTechSpecialists().then((resp) => setTechSpecialists(resp))
		getStatusReportsGroups().then(setStatusReportsGroups)
	}, [])

	const elModalRejectReport = () => {
		return (
			showModalRejectReport && (
				<Modal
					show={true}
					onHide={() => setShowModalRejectReport(false)}
					className="d-flex align-items-center justify-content-center steps-modal-wrapper auto-bet-modal-wrapper"
				>
					<div className="head-text text-center mb-3">
						Причина отклонения
					</div>
					<TextArea
						className="mb-2"
						placeholder="Оставьте свой комментарий"
						onInput={setComment}
					/>
					<div className="modal-actions mt-2">
						<button
							onClick={() => {
								postChangeStatusReportTransition(
									reportId,
									"need_corrections",
									{
										params: {
											comment: comment
										}
									}
								).then(() => {
									requestCar()
									setShowModalRejectReport(false)
								})
							}}
							className="btn btn-primary"
							type="button"
						>
							Отправить
						</button>
						<button
							onClick={() => setShowModalRejectReport(false)}
							className="btn btn-secondary"
							type="button"
						>
							Отменить
						</button>
					</div>
				</Modal>
			)
		)
	}

	const elModalCloseReport = () => {
		return (
			showModalCloseReport && (
				<Modal
					show={true}
					onHide={() => setShowModalCloseReport(false)}
					className="d-flex align-items-center justify-content-center steps-modal-wrapper auto-bet-modal-wrapper"
				>
					<div className="head-text text-center">
						Закрыть отчет о состоянии? Повторное открытие данного
						отчета будет недоступно.
					</div>
					<div className="modal-actions mt-2">
						<button
							onClick={() => {
								postChangeStatusReportTransition(
									reportId,
									"closed"
								).then(() => {
									requestCar()
									setShowModalCloseReport(false)
								})
							}}
							className="btn btn-primary"
							type="button"
						>
							Закрыть отчет
						</button>
						<button
							onClick={() => setShowModalCloseReport(false)}
							className="btn btn-secondary"
							type="button"
						>
							Отменить
						</button>
					</div>
				</Modal>
			)
		)
	}

	const handleInputChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value
		})
	}

	const elModalChangeResponsibleId = () => {
		return (
			showModalChangeResponsibleId && (
				<Modal
					show={true}
					onHide={() => setShowModalChangeResponsibleId(false)}
					className="d-flex align-items-center justify-content-center steps-modal-wrapper auto-bet-modal-wrapper"
				>
					<div className="head-text text-center">
						Технический специалист
					</div>
					<div className="d-flex flex-column mt-2 gap-20px">
						<div className="d-flex align-items-center">
							<Select
								options={techSpecialists?.data?.map((user) => ({
									value: user.id,
									label: user.full_name
								}))}
								valueId={form.responsible_id || ""}
								size="m"
								placeholder="Технический специалист"
								onChange={({ value }) =>
									handleInputChange({
										target: {
											name: "responsible_id",
											value
										}
									})
								}
							/>
						</div>
						<div className="modal-actions mt-2">
							<button
								onClick={() => {
									putStatusReportId(
										reportId,
										Number(form.responsible_id)
									).then(() => {
										setShowModalChangeResponsibleId(false)
										requestCar()
									})
								}}
								className="btn btn-primary"
								type="button"
							>
								Выбрать
							</button>
							<button
								onClick={() =>
									setShowModalChangeResponsibleId(false)
								}
								className="btn btn-secondary"
								type="button"
							>
								Отменить
							</button>
						</div>
					</div>
				</Modal>
			)
		)
	}

	const elActions = () => {
		const options = []

		if (meStore.hasPermission("admin.auctions.edit")) {
			options.push({
				label: "Редактировать ответственного",
				value: "edit",
				onClick: () => {
					setShowModalChangeResponsibleId(true)
				}
			})
		}

		if (
			!meStore.hasPermission("admin.auctions.edit") &&
			meStore.hasPermission("status-reports.transition") &&
			(report?.status?.code === "opened" ||
				report?.status?.code === "need_corrections")
		) {
			options.push({
				label: "Отправить на проверку",
				value: "in_progress",
				onClick: () => {
					postChangeStatusReportTransition(
						reportId,
						"in_progress"
					).then(() => {
						requestCar()
					})
				}
			})
		}

		if (
			meStore.hasPermission("admin.auctions.edit") &&
			report?.status?.code === "in_progress"
		) {
			options.push({
				label: "Отклонить отчет",
				value: "reject",
				onClick: () => {
					setShowModalRejectReport(true)
				}
			})
		}

		if (
			meStore.hasPermission("admin.auctions.edit") &&
			(report?.status?.code === "opened" ||
				report?.status?.code === "in_progress" ||
				report?.status?.code === "need_corrections")
		) {
			options.push({
				label: "Закрыть отчет",
				value: "close",
				onClick: () => {
					setShowModalCloseReport(true)
				}
			})
		}

		if (options.length) {
			return (
				<div className="d-flex actions">
					<div className="d-flex align-items-center action">
						<DropdownButton btnText="Действия" options={options} />
					</div>
				</div>
			)
		}
	}

	const statusReports = () => {
		if (statusReportsGroups) {
			return statusReportsGroups.map((item) => {
				if (!item.parent_id) {
					return (
						<Accordion key={item.id} defaultActiveKey="0">
							<Accordion.Item eventKey={item.id} key={item.id}>
								<ReportButton eventKey={item.id}>
									{item.name === "Состояние" ? (
										<img
											src="/img/icons/state-icon.svg"
											alt=""
										/>
									) : (
										<img
											src="/img/icons/settings-icon.svg"
											alt=""
										/>
									)}
									<div className="accordion-head">
										{item.name}
									</div>
								</ReportButton>
								{!disabledChangeDefects && (
									<Accordion.Body>
										<StateAccordion
											accordion={item}
											isMobile={isMobile}
											keyVechicle={keyVechicle}
											eventKey={item.id}
											isClosed={isClosedReport}
										/>
									</Accordion.Body>
								)}
							</Accordion.Item>
						</Accordion>
					)
				}
			})
		}
	}

	const getClassName = (status) => {
		if (status === "closed") {
			return "car-status green"
		}
		if (status === "in_progress") {
			return "car-status green"
		}
		if (status === "opened") {
			return "car-status orange"
		}
		if (status === "need_corrections") {
			return "car-status orange"
		}
	}

	const status = {
		opened: "На осмотре",
		in_progress: "На проверкe",
		need_corrections: meStore.hasPermission("admin.auctions.edit")
			? "Отчет в работе"
			: "Проверить отчет",
		closed: "Закрытый"
	}

	if (!car?.car) return null

	return (
		<div className="reports-wrapper reports-wrapper_padding">
			<div className="d-flex gap-20px mob-title-wrapper">
				<div className="title">
					Отчет о состоянии Автомарт №{reportId}
				</div>
				<div className={getClassName(report?.status?.code)}>
					{status[report?.status?.code]}
				</div>
			</div>

			<hr className="mt-5" />

			<div className="d-flex align-items-center justify-content-between mob-direction_column">
				<div className="d-flex align-items-center">
					<Link to={`/car/${car.id}`} className="brand-name">
						{car.car.brand.name} {car.car.model.name}
					</Link>
				</div>

				<div className="d-flex align-items-center mob-direction_column checked">
					{elActions()}
				</div>
			</div>

			<div className="car-info d-flex align-items-center justify-content-start">
				<div>
					<div className="d-flex align-items-center">
						<div className="key">Дата создания:</div>
						<div className="value">
							{report?.created_at
								? moment(report?.created_at).format(
									"DD.MM.YYYY"
								)
								: "-"}
						</div>
					</div>
					<div className="d-flex align-items-center mt-2">
						<div className="key">Дата закрытия:</div>
						<div className="value">
							{report?.closed_at
								? moment(report?.closed_at).format("DD.MM.YYYY")
								: "-"}
						</div>
					</div>
					<div className="d-flex align-items-center mt-2">
						<div className="key">Дата последнего изменения:</div>
						<div className="value">
							{report?.updated_at
								? moment(report?.updated_at).format(
									"DD.MM.YYYY"
								)
								: "-"}
						</div>
					</div>
					<div className="d-flex align-items-center mt-2">
						<div className="key">Произвел изменения:</div>
						<div className="value">
							{report?.updated_by?.full_name
								? report?.updated_by?.full_name
								: "-"}
						</div>
					</div>
				</div>
				<div>
					<div className="d-flex align-items-center">
						<div className="key">VIN:</div>
						<div className="value">{car.car.vin}</div>
					</div>
					{/*<div className="d-flex align-items-center mt-2">*/}
					{/*	<div className="key">Размещение автомобиля:</div>*/}
					{/*	<div className="value">{car.area?.name}</div>*/}
					{/*</div>*/}
					{/*<div className="d-flex align-items-center mt-2">*/}
					{/*	<div className="key">Площадка:</div>*/}
					{/*	<div className="value">{car.location?.name}</div>*/}
					{/*</div>*/}
					{/*<div className="d-flex align-items-center mt-2">*/}
					{/*	<div className="key">Город:</div>*/}
					{/*	<div className="value">{car.city?.name}</div>*/}
					{/*</div>*/}
					<div className="d-flex align-items-center mt-2">
						<div className="key">Технический специалист:</div>
						<div className="value">
							{report?.responsible?.full_name}
						</div>
					</div>
				</div>
			</div>

			{report?.comment && (
				<div className="car-info d-flex align-items-center justify-content-start">
					<div>
						<div className="d-flex align-items-center">
							<div className="key">Комментарий:</div>
							<div className="value">
								{report?.comment ? report?.comment : "-"}
							</div>
						</div>
					</div>
				</div>
			)}

			<div
				className={clsx(
					"accordion-wrapper",
					disabledChangeDefects && "disabled"
				)}
			>
				<Accordion activeKey={accordionCurrentKey}>
					<Accordion.Item eventKey="0">
						<ReportButtonSimple eventKey="0" onClick={() => setAccordionCurrentKey("0")}>
							<img src="/img/icons/required-report.svg" alt="" />
							<div className="accordion-head">Обязательные данные</div>
						</ReportButtonSimple>
						{!disabledChangeDefects && keyVechicle && report && (
							<Accordion.Body>
								<RequiredFieldsVehicle eventKey="0" keyType={keyVechicle} report={report}
									isClosed={isClosedReport} onClose={setAccordionCurrentKey} />
							</Accordion.Body>
						)}
					</Accordion.Item>
					<Accordion.Item eventKey="1">
						<ReportButtonSimple eventKey="1" onClick={() => setAccordionCurrentKey("1")}>
							<img src="/img/icons/documents-report.svg" alt="" />
							<div className="accordion-head">Документы</div>
						</ReportButtonSimple>
						{!disabledChangeDefects && report?.id && (
							<Accordion.Body>
								<DocumentsReport eventKey="1" id={report?.id} isClosed={isClosedReport}
									onClose={() => setAccordionCurrentKey("2")} />
							</Accordion.Body>
						)}
					</Accordion.Item>
					<Accordion.Item eventKey="2">
						<ReportButtonSimple eventKey="1" onClick={() => setAccordionCurrentKey("2")}>
							<img src="/img/icons/cars-report.svg" alt="" />
							<div className="accordion-head">Фотографии</div>
						</ReportButtonSimple>
						{!disabledChangeDefects && report?.id && (
							<Accordion.Body>
								<PhotoReport eventKey="2" id={report?.id} isClosed={isClosedReport}
									onClose={() => setAccordionCurrentKey("-1")} />
							</Accordion.Body>
						)}
					</Accordion.Item>
				</Accordion>
				{statusReports()}
				<Accordion defaultActiveKey="1">
					<Accordion.Item eventKey="2">
						<ReportButton eventKey="2">
							<img src="/img/icons/wheels-icon.svg" alt="" />
							<div className="accordion-head">Колёса</div>
						</ReportButton>
						{!disabledChangeDefects && (
							<Accordion.Body>
								<WheelsAccordion eventKey="2" isClosed={isClosedReport} />
							</Accordion.Body>
						)}
					</Accordion.Item>
					<Accordion.Item eventKey="3">
						<ReportButton eventKey="3">
							<img src="/img/icons/comment-icon.svg" alt="" />
							<div className="accordion-head">Комментарии</div>
						</ReportButton>
						{!disabledChangeDefects && (
							<Accordion.Body>
								<Comment id={report?.id} eventKey={'3'} isClosed={isClosedReport} />
							</Accordion.Body>
						)}
					</Accordion.Item>
				</Accordion>
			</div>
			{elModalChangeResponsibleId()}
			{elModalCloseReport()}
			{elModalRejectReport()}
		</div>
	)
})
