import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { meStore } from '@mobx/me';
import { NO_PERMISSION_URL } from '@utils/common';
import { Input } from '@components/input/input';
import { changeUserGroupRequest, createUserGroupRequest, getUserGroupRequest } from '@api/user-groups';
import { Loader } from '@components/loader/loader';

import './user-group-form.scss';

export const UserGroupForm = observer(({ isEdit }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(Boolean(isEdit));
  const [error, setError] = useState();
  const [form, setForm] = useState({
    name: '',
  });

  useEffect(() => {
    if (isEdit) {
      getUserGroupRequest(id).then((resp) => {
        setLoading(false);
        setForm({ name: resp.name });
      });
    }
  }, []);

  if (meStore.noPermission('groups.edit')) {
    return <Navigate to={NO_PERMISSION_URL} />;
  }

  if (meStore.data === null || loading) {
    return <Loader />;
  }

  const handleOnChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const saveGroup = () => {
    const data = { name: form.name };
    const request = isEdit ? changeUserGroupRequest(id, data) : createUserGroupRequest(data);

    request.then((resp) => {
      if (resp.message) {
        setError(resp.errors || resp.message);

        setTimeout(() => setError(''), 5000);
      } else {
        navigate(`/user-group/${resp.id}`);
      }
    });
  };

  return (
    <div className="add-user-group-wrapper">
      <div className="title">{isEdit ? 'Изменение' : 'Добавление'} группы пользователей</div>
      <div className="info-wrapper mt-4">
        <div className="d-flex align-items-center justify-content-between">
          <div className="name">Название группы</div>
          <div className="w-70">
            <Input
              name="name"
              onChange={handleOnChange}
              value={form.name}
              placeholder="Название"
            />
          </div>
        </div>
      </div>
      <button
        disabled={!form.name}
        onClick={saveGroup}
        className="btn btn-primary mt-3 mb-2 w-100"
        type="button"
      >
        {isEdit ? 'Сохранить группу' : 'Создать группу'}
      </button>
      {error && <div className="mt-2 mb-2 alert alert-danger">{JSON.stringify(error, null, ' ')}</div>}
    </div>
  );
});
